import React, { useState } from 'react';
import { useEffect} from 'react';
import ReactDOMServer from 'react-dom/server';
import Layout from '../../components/Layout/Layout'
import StepperComponent from '../../components/StepperComponent/StepperComponent';
import axios from 'axios';
import Chatbot from '../../components/Chatbot/Chatbot';
export default function Home() {


  const [name, setName] = useState('');

  useEffect(() => {
    // Récupérez l'id du consultant depuis le localStorage
    const consultantId = localStorage.getItem('id');
    // Vérifiez si l'id du consultant est présent dans le localStorage
    if (consultantId) {
      // Effectuez une requête GET vers l'endpoint /consultants/{id}
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/current-consultant/${consultantId}`, {
        headers: {
            'X-API-Key': '7TXbn5oeDakO8b45XV08HY3eXrn1M5IqYHOoXo', // Replace with your actual API key
            'Content-Type': 'application/json',
        },
      }).then(response => {
          // Mettez à jour le nom avec le nom du consultant
          setName(response.data.ConsultantPerimeter.Name);
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des données du consultant :', error);
        });
    }
  }, []);
  const [talents, setTalents] = useState([]);

  useEffect(() => {
    // Récupérez l'id du consultant depuis le localStorage
    const consultantId = localStorage.getItem('id');

    // Vérifiez si l'id du consultant est présent dans le localStorage
    if (consultantId) {
      // Effectuez une requête GET vers l'endpoint /getTalentsByConsultant/{IdConsultant}
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/talents/${consultantId}`, {
        headers: {
            'X-API-Key': '7TXbn5oeDakO8b45XV08HY3eXrn1M5IqYHOoXo', // Replace with your actual API key
            'Content-Type': 'application/json',
        },
      }).then(response => {
          // Mettez à jour la liste des talents avec les données renvoyées par l'API
          setTalents(response.data);
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des talents :', error);
        });
    }
  }, []); // Le tableau vide signifie que cet effet n'a besoin de s'exécuter qu'une seule fois après le montage initial.
  
  return (
    <div className='content'>
        <Layout>
          <Chatbot/>
          <h3 className='welcometitle'>Bienvenue {name}</h3>
          <div id="myChatbotContainer">
           
          </div>
        </Layout>   
    </div>
  )
}
