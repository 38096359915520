import {useState} from "react"
import Login from "../../pages/Login/Login";
const LeftSection = ({children}) => {
  const [login, setLogin] = useState(localStorage.getItem('id'));
    return (
      <div className="left-section {login ? ' ' : d-flex}">
        {login ?  children : <Login/>}
      </div>
    );
  };
export default LeftSection 