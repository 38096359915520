import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/login`,
        {
          email: email,
        },
        {
          headers: {
            'X-API-Key': '7TXbn5oeDakO8b45XV08HY3eXrn1M5IqYHOoXo',
            'Content-Type': 'application/json',
          },
        }
      );

      const token = response.data.token;
      const id = response.data.consultantId;
      console.log('token',token)
      if (token !== '') {
        // Stockez le token dans le contexte, le stockage local
        localStorage.setItem('token', token);
        localStorage.setItem('id', id);
        window.location.reload();
      }
    } catch (error) {
      console.error(
        'Erreur de connexion :',
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='logincontainer'>
      <div className='loginconetent'>
        <h2 className='login-title mb-4'>Se connecter</h2>
        <form className='login-form mb-4'>
          <div className='inputcontent d-flex flex-column'>
            <label className='label-login'>Email</label>
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='flex items-center justify-end mt-4 mb-4'>
            {/* Conditionally render the button or the loader */}
            {loading ? (
              <button className='submit-login'>
                <FontAwesomeIcon icon={faSpinner} spin size='1x' />
              </button>
            ) : (
              <button className='submit-login' onClick={handleLogin}>
                Connexion
              </button>
            )}
          </div>
          {/* <a className='' id='forgotpassword' href=''>
            Mot de passe oublié
          </a> */}
        </form>
      </div>
    </div>
  );
};

export default Login;
