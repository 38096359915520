import Stepper from 'react-stepper-horizontal';

function StepperComponent() {
  const steps = [
    { title: 'étape 1' },
    { title: 'étape 2' },
    { title: 'étape 3' },
  ];
  const activeStep = 1;

  return (
      <div className='w-100'>
          <Stepper
          steps={steps}
          activeStep={activeStep}/>
      </div>
  );
}

export default StepperComponent;