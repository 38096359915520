import React, { useEffect } from 'react';

const Chatbot = () => {
  useEffect(() => {
    // L'ajout du script se fait dans le <head> du document
    const existingScript = document.getElementById('HMB');
    if (!existingScript) {
      const script = document.createElement('script');
      script.setAttribute("async","");
      script.id = 'HMB';
      const consultantId = localStorage.getItem('id');
      script.setAttribute("consultantid",consultantId)
      script.src = 'https://botcore.hellomybot.io/v2/bundle';
      script.setAttribute('key', '8a1d1d42-1b4e-4221-afc6-7e168029f66d');
      script.setAttribute('target', 'myChatbotContainer');
      document.head.appendChild(script);
    }

    return () => {
    };
  }, []); // Le tableau de dépendances vide assure que cet effet s'exécute une fois lors du montage du composant

  return <></>;
};

export default Chatbot;
