import React from 'react';
import './App.css'; 
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from './components/Layout/Layout';
import { useMediaQuery } from 'react-responsive';
import Welcome from './pages/WelcomePages/Welcome';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';

function App() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  return (
    <div className="app-container">
      {/* {isMobile && <Welcome/>}
      {isTablet && <Home/>} */}
      <Home/>
    </div>
  );
}

export default App;
